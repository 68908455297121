export enum DeviceType {
  android = 'android',
  ios = 'ios',
  web = 'web'
}

export enum ConnectionStatusType {
  online = 'online',
  offline = 'offline'
}

export enum PushType {
  receivedChat = 'receivedChat',
  receivedMessage = 'receivedMessage',
  recommendFriend = 'recommendFriend',
  postCreatedComment = 'postCreatedComment',
  postLike = 'postLike',
  commentLike = 'commentLike',
}

export interface SavedDeviceTokenParam {
  token: string;
  userId: string;
  language: string;
  url: string;
}

export interface PixelPushData {
  title: string;
  body: string;
  imageUrl?: string;
  targetPath: string;
  type: PushType;
  badge: string;
}

export const PIXEL_NOTIFICATION_CHANNEL = 'pixel_notification_channel';
