<ion-app>
  <ion-router-outlet/>
  <app-loading-container/>
  @if (isDevPhase) {
    <div class="right-top">
      @if (currentPhoneNumber()) {
        <div class="current-user-info">
          {{ currentPhoneNumber()|phoneNumber }}
        </div>
      }
      @if (loggedIn()) {
        <button class="logout-button" (click)="onLogout()">로그아웃</button>
      }
    </div>
    <div class="current-phase-info">{{ phase }} {{ currentVersion() }} {{ currentLocation }} {{ countryCode() }}</div>
  }
</ion-app>
<ion-modal (didDismiss)="onDidDismiss()"
           [isOpen]="isOpenPhotoViewer()"
           cssClass="photo-viewer"
           [breakpoints]="[0, 1]"
           [handle]="false"
           mode="ios"
           [initialBreakpoint]="1"
           [style.--height]="'100%'"
           [style.--width]="'100%'">
  <ng-template>
    <app-photo-viewer-v2 (backed)="onClosePhotoViewer()"
                         (currentUrlChange)="onCurrentUrlChange($event)"
                         [currentUrl]="photoViewerParam().url ?? ''"
                         [images]="photoViewerParam().urls ?? []"/>
  </ng-template>
</ion-modal>
