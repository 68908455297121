import { type FirebaseMessagingPlugin } from '@capacitor-firebase/messaging';
import { defer, forkJoin, map, Observable, tap } from 'rxjs';

export let LazyFirebaseMessaging!: FirebaseMessagingPlugin;

export function loadLazyFirebaseMessaging(): Observable<void> {
  return forkJoin([
    defer(() => import('@capacitor-firebase/messaging')).pipe(tap(({ FirebaseMessaging: m }) => LazyFirebaseMessaging = m)),
  ]).pipe(map(() => undefined));
}
