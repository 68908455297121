import { inject } from '@angular/core';
import { AppConfigApiService } from '@pixels/client/app-config/app-config-api.service';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { retryCountExcludedStatusCodes } from '@pixels/client/utils/rx-retry';
import { map, Observable, tap } from 'rxjs';

export function syncConfigGuard(): Observable<boolean> {
  const appConfigApiService = inject(AppConfigApiService);
  const appInfo = inject(AppInfo);
  return appConfigApiService.fetchOne().pipe(
    tap(config => appInfo.syncConfig(config ?? {})),
    retryCountExcludedStatusCodes({
      maxRetryAttempts: 3,
      scalingDuration: 300,
      excludedStatusCodes: [400, 401, 403]
    }),
    map(_ => true)
  );
}
