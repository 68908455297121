import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PHASE_TOKEN } from '@pixels/client/injection-tokens/environment-token';
import { AppStateService } from '@pixels/client/ngforage/app-state.service';
import { Phase } from '@pixels/universal/model/phase-model';
import { noop } from 'es-toolkit';
import { defer, EMPTY, finalize, Observable, switchMap, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  private readonly appStateService = inject(AppStateService);
  private readonly isProd = inject(PHASE_TOKEN) === Phase.prod;
  private readonly activatedRoute = inject(ActivatedRoute);
  private log = console.log;
  private debug = console.debug;
  private error = console.error;
  private warn = console.warn;
  private info = console.info;

  constructor() {
    if (typeof console.warn === 'undefined') {
      console.warn = console.log;
    }
    if (typeof console.info === 'undefined') {
      console.info = console.log;
    }

    if (typeof console.error === 'undefined') {
      console.error = console.log;
    }

    this.activatedRoute.queryParamMap.pipe(
      switchMap(queryParamMap => {
        if (!queryParamMap.has('logger')) {
          return EMPTY;
        }
        const enableLogger = queryParamMap.get('logger') === 'true';
        return this.appStateService.setItem('loggerDisabled', !enableLogger).pipe(
          tap(() => enableLogger ? this.enable() : this.disable())
        );
      }),
    ).subscribe();
  }

  public enable(): void {
    console.log = this.log;
    console.debug = this.debug;
    console.error = this.error;
    console.warn = this.warn;
    console.info = this.info;
  }

  public disable(): void {
    console.log = noop;
    console.debug = noop;
    console.error = noop;
    console.warn = noop;
    console.info = noop;
  }

  public init(): Observable<boolean> {
    return defer(() => {
      console.time('init-logger');
      return this.appStateService.getItemNonNullable('loggerDisabled', this.isProd);
    }).pipe(
      tap(disabled => disabled ? this.disable() : this.enable()),
      finalize(() => console.timeEnd('init-logger'))
    );
  }
}

export function initLogger(service: LoggerService): () => Observable<boolean> {
  return () => service.init();
}
