import { DOCUMENT } from '@angular/common';
import { inject, Injectable, Renderer2 } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { catchError, debounceTime, defer, filter, finalize, Observable, of, race, switchMap, take, timer } from 'rxjs';

const TARGET_ID = 'spinner-container';
const MAX_TIME = 10000;

@Injectable()
export class GlobalSpinnerV2Service {
  private readonly router = inject(Router);
  private readonly doc = inject(DOCUMENT);
  private readonly renderer = inject(Renderer2);

  public get hidePreloader$(): Observable<Event> {
    return race([
      this.navigationEnd(),
      timer(MAX_TIME)
    ]).pipe(
      switchMap(() => this.hidePreloader()),
    );
  }

  private navigationEnd(): Observable<NavigationEnd | null> {
    return this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      take(1),
      debounceTime(100),
      catchError(err => of(null)),
    );
  }

  private hidePreloader(): Observable<any> {
    return defer(() => {
      const preloaderEl = this.doc.getElementById(TARGET_ID);
      if (!preloaderEl) {
        return of(undefined);
      }
      this.renderer.addClass(preloaderEl, 'hide');
      return timer(300).pipe(
        finalize(() => this.renderer.removeChild(preloaderEl.parentElement, preloaderEl))
      );
    });
  }
}
