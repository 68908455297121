import { inject, Injectable } from '@angular/core';
import type {
  GetTokenResult,
  ListChannelsResult,
  NotificationActionPerformedEvent,
  NotificationReceivedEvent
} from '@capacitor-firebase/messaging';
import { Notification } from '@capacitor-firebase/messaging';
import { CreateChannelOptions, DeleteChannelOptions } from '@capacitor-firebase/messaging/dist/esm/definitions';
import { App } from '@capacitor/app';
import { Capacitor, type PluginListenerHandle } from '@capacitor/core';
import { type ActionPerformed } from '@capacitor/local-notifications';
import { Platform } from '@ionic/angular/standalone';
import { GAEventType, UserAuditType } from '@pixels/client/anayltics/analytics.model';
import { AnalyticsService } from '@pixels/client/anayltics/analytics.service';
import { LazyFirebaseMessaging, loadLazyFirebaseMessaging } from '@pixels/client/lazy/lazy-capacitor-firebase-messaging';
import { LazyLocalNotifications, lazyLocalNotifications } from '@pixels/client/lazy/lazy-local-notifications';
import { isSafari } from '@pixels/client/utils/user-agent';
import { PixelPushData } from '@pixels/universal/model/push-token/push.model';
import { captureException } from '@sentry/angular';
import { catchError, defer, EMPTY, firstValueFrom, map, Observable, of, retry, share, switchMap, tap, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IonicPushService {
  public readonly pushNotificationReceived$ = this.createPushNotificationReceived();
  public readonly pushNotificationActionPerformed$ = this.createPushNotificationActionPerformed();
  public readonly localPushNotificationActionPerformed$ = this.createLocalPushNotificationActionPerformed();
  private readonly analytics = inject(AnalyticsService);
  private readonly platform = inject(Platform);
  private readonly isNative = this.platform.is('hybrid');
  private readonly isIos = this.platform.is('ios');

  public checkPermissionLocalNotification(): Observable<any> {
    return lazyLocalNotifications().pipe(
      switchMap(() => LazyLocalNotifications.checkPermissions()),
      switchMap(permissionStatus => {
        if (permissionStatus.display === 'prompt') {
          return defer(() => LazyLocalNotifications.requestPermissions());
        }
        return of(permissionStatus);
      }),
      tap(permissionStatus => {
        if (permissionStatus.display !== 'granted') {
          this.analytics.emitLogEvent({ name: `${GAEventType.userAudit}_${UserAuditType.deniedLocalNotification}` });
        }
      }),
    );
  }

  public register(vapidKey: string): Observable<GetTokenResult> {
    return loadLazyFirebaseMessaging().pipe(
      switchMap(() => defer(() => LazyFirebaseMessaging.checkPermissions()).pipe(
        switchMap(permissionStatus => {
          if (permissionStatus.receive === 'prompt') {
            return defer(() => LazyFirebaseMessaging.requestPermissions());
          }
          return of(permissionStatus);
        }),
        switchMap(permissionStatus => {
          if (permissionStatus.receive !== 'granted') {
            this.analytics.emitLogEvent({ name: `${GAEventType.userAudit}_${UserAuditType.deniedPush}` });
            return of({ token: '' });
          }
          // console.log(permissionStatus, Capacitor.getPlatform());
          if (Capacitor.getPlatform() === 'web') {
            if (isSafari && (this.platform.is('desktop') || this.platform.is('mobileweb'))) {
              return of({ token: '' });
            }

            if ('serviceWorker' in navigator) {
              return defer(() => navigator.serviceWorker.register('firebase-messaging-sw.js')).pipe(
                switchMap(serviceWorkerRegistration => {
                  if (!serviceWorkerRegistration) {
                    this.analytics.emitLogEvent({ name: `${GAEventType.userAudit}_${UserAuditType.loadErrorServiceWorker}` });
                    return of({ token: '' });
                  }

                  return defer(() => LazyFirebaseMessaging.getToken({
                    vapidKey,
                    serviceWorkerRegistration,
                  })).pipe(
                    retry({
                      count: 2,
                      delay: (error, retryCount) => defer(async () => {
                        await LazyFirebaseMessaging.requestPermissions();
                        return firstValueFrom(timer(retryCount * 500).pipe(
                          map(() => ({ token: '' }))
                        ));
                      })
                    })
                  );
                }),
              );
            } else {
              return of({ token: '' });
            }
          }
          return defer(() => LazyFirebaseMessaging.getToken());
        })
      ))
    );
  }

  public unregister(): Observable<void> {
    return loadLazyFirebaseMessaging().pipe(switchMap(() => LazyFirebaseMessaging.deleteToken()));
  }

  public triggerLocalNotifications(v: Notification): Observable<any> {
    return defer(() => {
      if (!(this.isNative && this.isIos)) {
        return of(undefined);
      }

      return defer(() => App.getState()).pipe(
        switchMap(({ isActive }) => {
          if (isActive) {
            return of(undefined);
          }
          const { title, body, targetPath, type } = v.data as PixelPushData;
          return lazyLocalNotifications().pipe(
            switchMap(() => LazyLocalNotifications.schedule({
              notifications: [
                {
                  id: Date.now(),
                  schedule: { at: new Date(Date.now() + 500) },
                  title,
                  body,
                  extra: { targetPath, type },
                  sound: 'default',
                }
              ]
            }))
          );
        })
      );
    });
  }

  public listChannels(): Observable<ListChannelsResult> {
    return loadLazyFirebaseMessaging().pipe(
      switchMap(() => LazyFirebaseMessaging.listChannels())
    );
  }

  public createChannel(options: CreateChannelOptions): Observable<void> {
    return loadLazyFirebaseMessaging().pipe(
      switchMap(() => LazyFirebaseMessaging.createChannel(options))
    );
  }

  public deleteChannel(options: DeleteChannelOptions): Observable<void> {
    return loadLazyFirebaseMessaging().pipe(
      switchMap(() => LazyFirebaseMessaging.deleteChannel(options))
    );
  }

  private createPushNotificationReceived(): Observable<NotificationReceivedEvent> {
    return new Observable<NotificationReceivedEvent>(observer => {
      let handle: PluginListenerHandle | undefined;
      const subscription = loadLazyFirebaseMessaging().pipe(
        switchMap(() => LazyFirebaseMessaging.addListener('notificationReceived', event => observer.next(event))),
        tap(h => handle = h)
      ).subscribe();
      return async () => {
        subscription.unsubscribe();
        await handle?.remove();
      };
    }).pipe(
      catchError(err => {
        captureException(err, { tags: { from: 'notificationReceived' } });
        console.error(err);
        return EMPTY;
      }),
      share()
    );
  }

  private createPushNotificationActionPerformed(): Observable<NotificationActionPerformedEvent> {
    return new Observable<NotificationActionPerformedEvent>(observer => {
      let handle: PluginListenerHandle | undefined;
      const subscription = loadLazyFirebaseMessaging().pipe(
        switchMap(() => LazyFirebaseMessaging.addListener('notificationActionPerformed', notification => {
          console.log('notificationActionPerformed', notification);
          observer.next(notification);
        })),
        tap(h => handle = h)
      ).subscribe();
      return async () => {
        subscription.unsubscribe();
        await handle?.remove();
      };
    }).pipe(
      catchError(err => {
        captureException(err, { tags: { from: 'notificationActionPerformed' } });
        console.error(err);
        return EMPTY;
      }),
      share()
    );
  }

  private createLocalPushNotificationActionPerformed(): Observable<ActionPerformed> {
    return new Observable<ActionPerformed>(observer => {
      let handle: PluginListenerHandle | undefined;
      const subscription = lazyLocalNotifications().pipe(
        switchMap(() => LazyLocalNotifications.addListener('localNotificationActionPerformed', notification => {
          console.log('localNotificationActionPerformed', notification);
          observer.next(notification);
        })),
        tap(h => handle = h)
      ).subscribe();
      return async () => {
        subscription.unsubscribe();
        await handle?.remove();
      };
    }).pipe(
      catchError(err => {
        console.error(err);
        captureException(err, { tags: { from: 'localNotificationActionPerformed' } });
        return EMPTY;
      }),
      share()
    );
  }
}
