import { Component, inject } from '@angular/core';
import { IonLoading } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingContainerService } from './loading-container.service';

@Component({
  selector: 'app-loading-container',
  templateUrl: './loading-container.component.html',
  styleUrl: './loading-container.component.scss',
  imports: [
    IonLoading,
    TranslateModule,
  ],
  standalone: true
})
export class LoadingContainerComponent {
  private readonly loadingContainerService = inject(LoadingContainerService);
  public readonly isLoading = this.loadingContainerService.isLoading;
}
