import { isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  DEFAULT_LANGUAGE_TOKEN,
  getLanguageCode,
  LANGUAGE_BY_BROWSER_TOKEN,
  SUPPORT_LANGUAGE_CODES_TOKEN
} from '@pixels/client/i18n-util/i18n-util';
import { AppStateService } from '@pixels/client/ngforage/app-state.service';
import { REQUEST } from '@pixels/client/ssr/express-tokens';
import { Lang } from '@pixels/universal/model/i18n/i18n.model';
import { getLangFromAcceptLanguage } from '@pixels/universal/util/accept-language-util';
import { catchError, defer, finalize, map, Observable, of, switchMap } from 'rxjs';

const LANGUAGE_QUERY_PARAM = 'lang';
let initialized = false;

export function i18nGuard(next: ActivatedRouteSnapshot): Observable<boolean> {
  const SUPPORT_LANGUAGES = inject(SUPPORT_LANGUAGE_CODES_TOKEN);
  const isLangByBrowser = inject(LANGUAGE_BY_BROWSER_TOKEN);
  const DEFAULT_LANGUAGE = inject(DEFAULT_LANGUAGE_TOKEN);
  const translate = inject(TranslateService);
  const appState = inject(AppStateService);
  const isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  translate.addLangs(SUPPORT_LANGUAGES);
  translate.setDefaultLang(DEFAULT_LANGUAGE);
  const request = inject(REQUEST, { optional: true });

  function setLanguage(lang: string): Observable<string | undefined> {
    return defer(() => {
      const languageCode = getLanguageCode(lang, SUPPORT_LANGUAGES);
      if (languageCode && SUPPORT_LANGUAGES.includes(languageCode)) {
        return translate.use(lang).pipe(
          switchMap(() => appState.setItem('savedLanguage', lang)),
          catchError(() => of(undefined))
        );
      }
      return of(undefined);
    });
  }

  function initialSetLanguage(): Observable<boolean> {
    return defer(() => {
      console.time('initialSetLanguage');
      if (initialized) {
        return of(true);
      }
      initialized = true;
      if (isBrowser) {
        return appState.getItem('savedLanguage').pipe(
          switchMap(savedLang => {
            if (savedLang && !isLangByBrowser) {
              const languageCode = getLanguageCode(savedLang, SUPPORT_LANGUAGES);
              if (languageCode && SUPPORT_LANGUAGES.includes(languageCode)) {
                return translate.use(savedLang);
              }
            }
            let browserLang = translate.getBrowserLang() as Lang;
            if (!SUPPORT_LANGUAGES.includes(browserLang)) {
              browserLang = DEFAULT_LANGUAGE;
            }
            return translate.use(browserLang);
          }),
        );
      } else {
        initialized = false;
        let browserLang = getLangFromAcceptLanguage(request?.header('accept-language') ?? '', SUPPORT_LANGUAGES, DEFAULT_LANGUAGE);
        if (!SUPPORT_LANGUAGES.includes(browserLang)) {
          browserLang = DEFAULT_LANGUAGE;
        }
        return translate.use(browserLang);
      }
    }).pipe(
      finalize(() => console.timeEnd('initialSetLanguage'))
    );
  }

  return defer(() => {
    if (next.queryParamMap.has(LANGUAGE_QUERY_PARAM)) {
      const lang = next.queryParamMap.get(LANGUAGE_QUERY_PARAM) as string;
      const languageCode = getLanguageCode(lang, SUPPORT_LANGUAGES);
      if (languageCode && SUPPORT_LANGUAGES.includes(languageCode)) {
        initialized = true;
        return setLanguage(lang);
      }
    }
    return initialSetLanguage();
  }).pipe(
    map(() => true)
  );
}
