import { type LocalNotificationsPlugin } from '@capacitor/local-notifications';
import { defer, map, Observable, tap } from 'rxjs';

export let LazyLocalNotifications!: LocalNotificationsPlugin;

export function lazyLocalNotifications(): Observable<void> {
  return defer(() => import('@capacitor/local-notifications')).pipe(
    tap(({ LocalNotifications: m }) => LazyLocalNotifications = m),
    map(() => undefined)
  );
}
