import { inject } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { DEFAULT_LANGUAGE_TOKEN, SUPPORT_LANGUAGE_CODES_TOKEN } from '@pixels/client/i18n-util/i18n-util';
import { Lang } from '@pixels/universal/model/i18n/i18n.model';
import { catchError, defer, forkJoin, map, Observable, of, switchMap } from 'rxjs';

export class AppI18nLoader implements TranslateLoader {
  private readonly supportLanguageCodes = inject(SUPPORT_LANGUAGE_CODES_TOKEN);
  private readonly defaultLang = inject(DEFAULT_LANGUAGE_TOKEN);

  getTranslation(lang: Lang): Observable<any> {
    return of(this.supportLanguageCodes.includes(lang) ? lang : this.defaultLang).pipe(
      switchMap(validLang => forkJoin({
        main: defer(() => import(`../i18n/${validLang}.json`)).pipe(catchError(() => defer(() => import(`../i18n/${this.defaultLang}.json`)))),
        common: defer(() => import(`../../../../libs/client/src/i18n/${validLang}.json`)).pipe(catchError(() => defer(() => import(`../../../../libs/client/src/i18n/${this.defaultLang}.json`)))),
      })),
      map(({ main, common }) => ({ ...main.default, ...common.default }))
    );
  }
}
