import { inject, Injectable, signal } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform } from '@ionic/angular/standalone';
import { ONE_ON_ONE_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyAiChat } from '@pixels/universal/model/apps/app-types';
import { AiChatProjectMeta } from '@pixels/universal/model/apps/project-meta/ai-chat-project-meta';

@Injectable({ providedIn: 'root' })
export class AiChatAppInfo extends AppInfo {
  public readonly appType = AppKeyAiChat;
  public readonly serviceName = _('@AI_동호회@');
  public readonly appStoreUrl = signal(AiChatProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${AiChatProjectMeta.appId}`);

  public override readonly admobBannerVideoChatAppId = (inject(Platform).is('ios') ? AiChatProjectMeta.ios?.admobBannerVideoChatAppId : AiChatProjectMeta.android?.admobBannerVideoChatAppId) ?? '';
  public override readonly loginTitle = _('@AI_동호회@');
  public override readonly loginDescription = _('@최신_인공지능_정보를_공유해요@');
  public override readonly loginAppIconUrl = 'https://cdn.pixelteam.io/pixels/app-icons/aichat-app-icon.svg';
  public override readonly bannerPlaceHolder = _('@AI_동호회_인공지능_SNS_커뮤니티@');
  public override readonly adminMenus = ONE_ON_ONE_ADMIN_MENUS;
  public override readonly enabledReferralFriendNotification: boolean = false;
  public override readonly enabledWaitingRoomInitGenderFilter: boolean = false;
}
