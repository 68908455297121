import { inject } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { Role } from '@pixels/universal/model/user/role.enum';
import { defer, map, Observable, of, switchMap, take } from 'rxjs';

export function adminGuard(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  const navCtrl = inject(NavController);
  const auth = inject(Auth);
  const loginPath = inject(AppInfo).loginPath;

  return authState(auth).pipe(
    take(1),
    switchMap(u => u ? u.getIdTokenResult() : of({ claims: { roles: [] } })),
    switchMap(({ claims }) => {
      if ((claims.roles as Role[])?.includes(Role.Admin)) {
        return of(true);
      }

      return defer(() => navCtrl.navigateRoot(loginPath)).pipe(map(() => false));
    })
  );
}

