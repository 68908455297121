import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { API_URL_TOKEN } from '@pixels/client/injection-tokens/api-url-token';
import { Observable } from 'rxjs';

export function apiWrapperInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const apiUrl = inject(API_URL_TOKEN);
  const url = req.url;
  if (/^\//.test(url)) {
    req = req.clone({
      url: apiUrl + req.url
    });
  }

  return next(req);
}
