import { adminRoutes } from '@pixels/client/pages/admin/admin.constant';

const COMMON_ADMIN_MENUS = [
  { title: '사용자 관리', url: adminRoutes.adminUsers.fullPath, icon: 'mail' },
  { title: '채팅방 관리', url: adminRoutes.adminChatRooms.fullPath, icon: 'paper-plane' },
  { title: '메시지 관리', url: adminRoutes.adminChatMessages.fullPath, icon: 'heart' },
  { title: '문의하기', url: adminRoutes.adminQuestions.fullPath, icon: 'heart' },
  { title: '신고하기', url: adminRoutes.adminReports.fullPath, icon: 'heart' },
];

export const KPOP_PHOTO_ADMIN_MENUS: AdminMenu[] = [
  { title: 'KPOP PHOTO 관리', url: adminRoutes.adminKpopPhotos.fullPath, icon: 'heart' },
];

export const PIXEL_ADMIN_MENUS: AdminMenu[] = [
  { title: '문의', url: adminRoutes.adminImsoloQuestions.fullPath, icon: 'heart' },
  { title: '운영자 관리', url: adminRoutes.adminUsers.fullPath, icon: 'mail' },
  ...KPOP_PHOTO_ADMIN_MENUS
];

export const OPENCHAT_ADMIN_MENUS = [
  ...COMMON_ADMIN_MENUS,
  { title: '카테고리 관리', url: adminRoutes.adminCategories.fullPath, icon: 'heart' },
];

export const ONE_ON_ONE_ADMIN_MENUS: AdminMenu[] = [
  ...COMMON_ADMIN_MENUS,
  { title: '앱관리', url: adminRoutes.adminAppConfig.fullPath, icon: 'heart' },
  { title: 'OneOnOne 사용자 관리', url: adminRoutes.adminInstaChatUsers.fullPath, icon: 'heart' },
  { title: 'OneOnOne 통계', url: adminRoutes.adminStats.fullPath, icon: 'heart' },
];

export const IMSOLO_ADMIN_MENUS: AdminMenu[] = [
  { title: '투표관리', url: adminRoutes.adminImsoloPolls.fullPath, icon: 'heart' },
  { title: '명예의 전당', url: adminRoutes.adminImsoloHallOfFames.fullPath, icon: 'heart' },
  { title: '나는솔로 법칙', url: adminRoutes.adminImsoloRules.fullPath, icon: 'heart' },
  { title: '출연자 관리', url: adminRoutes.adminImsoloPerformers.fullPath, icon: 'heart' },
  { title: '이름별 순위', url: adminRoutes.adminImsoloNameRanking.fullPath, icon: 'heart' },
  { title: '관상테스트', url: adminRoutes.adminMatchingResults.fullPath, icon: 'heart' },
  { title: '퍼블릭문의', url: adminRoutes.adminImsoloQuestions.fullPath, icon: 'heart' },
  { title: '운영자 관리', url: adminRoutes.adminUsers.fullPath, icon: 'mail' },
];

export const KPOP_ONLY_ADMIN_MENUS: AdminMenu[] = [
  { title: 'KPOP 매칭 결과', url: adminRoutes.adminMatchingResults.fullPath, icon: 'heart' },
  { title: 'KPOP 매칭 관리', url: adminRoutes.adminKpopIdolMatches.fullPath, icon: 'heart' },
  { title: 'KPOP 멤버 관리', url: adminRoutes.adminKpopIdolMembers.fullPath, icon: 'heart' },
  { title: 'KPOP 그룹 관리', url: adminRoutes.adminKpopIdolGroups.fullPath, icon: 'heart' },
];

export interface AdminMenu {
  title: string;
  url: string;
  icon: string;
}
