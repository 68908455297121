import { HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';
import { InstaChatUserApiService } from '@pixels/client/one-on-one/core/services/insta-chat-user-api.service';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { InstaChatUserForUserInfo } from '@pixels/universal/model/insta-chat/insta-chat-user/insta-chat-user.schema';
import { captureException } from '@sentry/angular';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';

export function checkPunishmentGuard(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  const instaChatUserApiService = inject(InstaChatUserApiService);
  const navCtrl = inject(NavController);
  const punishmentPath = inject(AppInfo).punishmentPath;

  return instaChatUserApiService.fetchMe().pipe(
    catchError(err => {
      if ([HttpStatusCode.Unauthorized].includes(err.status)) {
        return of(undefined);
      }
      return throwError(() => err);
    }),
    switchMap((user: InstaChatUserForUserInfo | undefined) => {
      if (!user) {
        return of(true);
      }
      if (user.punishmentEndedAt && new Date(user.punishmentEndedAt as any).getTime() > Date.now()) {
        setTimeout(() => navCtrl.navigateRoot(punishmentPath, {
          queryParams: { returnUrl: state.url },
        }), 100);
        return of(false);
      }
      return of(true);
    }),
    catchError(err => {
      captureException(err, { tags: { from: 'checkPunishmentGuard' } });
      console.error(err);
      return of(true);
    }),
  );
}
