import { Routes } from '@angular/router';
import { chatUserResolveFn } from '@pixels/client/chat-auth/chat-auth.service';
import { adminGuard } from '@pixels/client/guards/admin.guard';
import { alreadyInstaChatUserProfileGuard } from '@pixels/client/guards/already-insta-chat-user-profile.guard';
import { alreadyLoggedInGuard } from '@pixels/client/guards/already-logged-in.guard';
import { authGuard } from '@pixels/client/guards/auth.guard';
import { checkPunishmentGuard } from '@pixels/client/guards/check-punishment.guard';
import { checkRedirectGuard } from '@pixels/client/guards/check-redirect.guard';
import { beforeDeactivated } from '@pixels/client/guards/deactivated/check-can-deactivate.service';
import { DEFAULT_GUARDS } from '@pixels/client/guards/guard-model';
import { instaChatConfigGuard } from '@pixels/client/guards/insta-chat-config.guard';
import { needUpdateInstaChatUserGuard } from '@pixels/client/guards/need-update-insta-chat-user.guard';
import { appVersionResolveFn } from '@pixels/client/ionic/ionic-app';
import { chatRoomResolveFn } from '@pixels/client/one-on-one/core/services/chat-room-resolve';
import { adminRoutes } from '@pixels/client/pages/admin/admin.constant';
import { lazyLoadError } from '@pixels/client/router/lazy-load-error';
import { PATH_MAP } from './core/constant';
import { instaChatUserResolveFn } from './core/services/insta-chat-user-api.service';
import { settingResolveFn } from './core/services/insta-chat-user-setting-api.service';

export function getRoutes(isDev: boolean): Routes {
  return [
    {

      path: PATH_MAP.boardPost.path,
      canDeactivate: [beforeDeactivated],
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard],
      loadComponent: () => import('@pixels/client/one-on-one/pages/board-post/board-post.page').then(m => m.BoardPostPage).catch(lazyLoadError)
    },
    {
      path: PATH_MAP.boardPostsByCategory.path,
      canDeactivate: [beforeDeactivated],
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard],
      loadComponent: () => import('@pixels/client/one-on-one/pages/tabs/board-posts/board-posts.page').then(m => m.BoardPostsPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.checkRedirect.path,
      canActivate: [...DEFAULT_GUARDS, checkRedirectGuard],
      loadComponent: () => import('@pixels/client/pages/check-redirect/check-redirect.page').then((m) => m.CheckRedirectPage).catch(lazyLoadError),
    },

    {
      path: PATH_MAP.static.path,
      canActivate: [...DEFAULT_GUARDS],
      loadComponent: () => import('@pixels/client/pages/static-viewer/static-viewer.page').then((m) => m.StaticViewerPage).catch(lazyLoadError),
    },

    {
      path: PATH_MAP.usernameSetting.path,
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard, authGuard, alreadyInstaChatUserProfileGuard],
      loadComponent: () => import('@pixels/client/one-on-one/pages/insta-chat-user-edit/insta-chat-user-edit.page').then(m => m.InstaChatUserEditPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.chatRoom.path,
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard, checkPunishmentGuard, needUpdateInstaChatUserGuard],
      resolve: {
        chatUser: chatUserResolveFn,
        chatRoom: chatRoomResolveFn,
      },
      loadComponent: () => import('@pixels/client/one-on-one/pages/chat-room/chat-room.page').then(m => m.ChatRoomPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.myProfileEdit.path,
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard, authGuard, checkPunishmentGuard, needUpdateInstaChatUserGuard],
      canDeactivate: [beforeDeactivated],
      loadComponent: () => import('@pixels/client/one-on-one/pages/my-profile-edit/my-profile-edit.page').then(m => m.MyProfileEditPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.otherProfile.path,
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard, needUpdateInstaChatUserGuard],
      loadComponent: () => import('@pixels/client/one-on-one/pages/insta-chat-profile/insta-chat-profile.page').then(m => m.InstaChatProfilePage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.mySetting.path,
      resolve: {
        chatUser: chatUserResolveFn,
        setting: settingResolveFn,
        appVersion: appVersionResolveFn
      },
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard, authGuard, checkPunishmentGuard, needUpdateInstaChatUserGuard],
      loadComponent: () => import('@pixels/client/one-on-one/pages/setting/setting.page').then(m => m.SettingPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.login.path,
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard, alreadyLoggedInGuard],
      loadComponent: () => import('@pixels/client/pages/login/login.page').then(m => m.LoginPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.postNotification.path,
      resolve: {
        postNotification: settingResolveFn,
        instaChatUser: instaChatUserResolveFn,
      },
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard, authGuard, checkPunishmentGuard, needUpdateInstaChatUserGuard],
      loadComponent: () => import('@pixels/client/one-on-one/pages/post-notification/post-notification.page').then(m => m.PostNotificationPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.recommendedFriendsNotification.path,
      resolve: {
        recommendedFriendsNotification: settingResolveFn,
        instaChatUser: instaChatUserResolveFn,
      },
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard, authGuard, checkPunishmentGuard, needUpdateInstaChatUserGuard],
      loadComponent: () => import('@pixels/client/one-on-one/pages/recommended-friends-notification/recommended-friends-notification.page').then(m => m.RecommendedFriendsNotificationPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.myQuestion.path,
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard, authGuard, checkPunishmentGuard, needUpdateInstaChatUserGuard],
      loadComponent: () => import('@pixels/client/pages/my-question/pages/main/my-question.page').then(m => m.MyQuestionPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.myQuestionView.path,
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard, authGuard, checkPunishmentGuard, needUpdateInstaChatUserGuard],
      loadComponent: () => import('@pixels/client/pages/my-question/pages/view/my-question-view.page').then(m => m.MyQuestionViewPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.unregister.path,
      canActivate: [...DEFAULT_GUARDS],
      loadComponent: () => import('@pixels/client/one-on-one/pages/unregister/unregister.page').then(m => m.UnregisterPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.unregisterComplete.path,
      canActivate: [...DEFAULT_GUARDS, alreadyLoggedInGuard],
      loadComponent: () => import('@pixels/client/one-on-one/pages/unregister-complete/unregister-complete.page').then(m => m.UnregisterCompletePage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.underMaintenance.path,
      canActivate: [...DEFAULT_GUARDS],
      loadComponent: () => import('@pixels/client/pages/under-maintenance/under-maintenance.page').then(m => m.UnderMaintenancePage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.versionUpWaiting.path,
      canActivate: [...DEFAULT_GUARDS],
      loadComponent: () => import('@pixels/client/pages/version-up-waiting/version-up-waiting.page').then(m => m.VersionUpWaitingPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.punishment.path,
      canActivate: [...DEFAULT_GUARDS, authGuard],
      loadComponent: () => import('@pixels/client/one-on-one/pages/punishment/punishment.page').then(m => m.PunishmentPage).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.appInstall.path,
      canActivate: [...DEFAULT_GUARDS],
      loadComponent: () => import('@pixels/client/pages/app-install/app-install.page').then(m => m.AppInstallPage).catch(lazyLoadError)
    },

    {
      path: adminRoutes.admin.path,
      canActivate: [...DEFAULT_GUARDS, authGuard, checkPunishmentGuard, needUpdateInstaChatUserGuard, adminGuard],
      loadComponent: () => import('@pixels/client/pages/admin/admin.page').then(m => m.AdminPage).catch(lazyLoadError),
      loadChildren: () => import('@pixels/client/pages/admin/admin.routes').then(m => m.ADMIN_ROUTES).catch(lazyLoadError)
    },

    {
      path: PATH_MAP.bookmarklet.path,
      canActivate: [...DEFAULT_GUARDS, instaChatConfigGuard],
      loadComponent: () => import('@pixels/client/pages/bookmarklet/bookmarklet.page').then(m => m.BookmarkletPage).catch(lazyLoadError)
    },

    ...(isDev ? [
      {
        path: PATH_MAP.keyboardTest.path,
        canActivate: [...DEFAULT_GUARDS],
        loadComponent: () => import('@pixels/client/one-on-one/test-pages/keyboard-test/keyboard-test.page').then(m => m.KeyboardTestPage).catch(lazyLoadError)
      },

      {
        path: PATH_MAP.videoChat.path,
        canActivate: [...DEFAULT_GUARDS],
        loadComponent: () => import('@pixels/client/one-on-one/test-pages/video-chat/video-chat.page').then(m => m.VideoChatPage).catch(lazyLoadError)
      },

      {
        path: PATH_MAP.photoViewerTest.path,
        canActivate: [...DEFAULT_GUARDS],
        loadComponent: () => import('@pixels/client/one-on-one/test-pages/simple-photo-viewer/simple-photo-viewer.page').then(m => m.SimplePhotoViewerPage).catch(lazyLoadError)
      },
      {
        data: {
          videoId: '3yNbYeJSXg4',
          autoplay: true
        },
        path: PATH_MAP.youtubeTest.path,
        canActivate: [...DEFAULT_GUARDS],
        loadComponent: () => import('@pixels/client/youtube-embed/youtube-embed.component').then(m => m.YoutubeEmbedComponent).catch(lazyLoadError)
      },

      {
        path: 'test-iframe',
        canActivate: [...DEFAULT_GUARDS],
        loadComponent: () => import('@pixels/client/one-on-one/pages/tabs/iframe-viewer2/iframe-viewer2.page').then(m => m.IframeViewer2Page).catch(lazyLoadError)
      },
    ] : []),

    {
      path: '**',
      redirectTo: PATH_MAP.login.path,
      pathMatch: 'full'
    },
  ];
}
