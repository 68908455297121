import { registerLocaleData } from '@angular/common';
import localeKo from '@angular/common/locales/ko';
import { Component, inject, Injector, OnInit, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IonApp, IonModal, IonRouterOutlet } from '@ionic/angular/standalone';
import { AppInjector } from '@pixels/client/app-injector/app.injector';
import { LoadingContainerComponent } from '@pixels/client/components/loading-container/loading-container.component';
import { PhotoViewerV2Component } from '@pixels/client/components/photo-viewer-v2/photo-viewer-v2.component';
import { GlobalSpinnerV2Service } from '@pixels/client/global-spinner/global-spinner-v2.service';
import { PHASE_TOKEN } from '@pixels/client/injection-tokens/environment-token';
import { registerDialogWithBackButton } from '@pixels/client/ionic/ionic-helper';
import { CountryCodeService } from '@pixels/client/language/country-code.service';
import { isDevPhase } from '@pixels/client/phase/phase.constant';
import { PhoneNumberPipe } from '@pixels/client/pipes/phone-number/phone-number.pipe';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    IonApp,
    IonRouterOutlet,
    IonModal,
    LoadingContainerComponent,
    PhoneNumberPipe,
    PhotoViewerV2Component,
  ],
  providers: [
    AppService,
    GlobalSpinnerV2Service
  ],
})
export class AppComponent implements OnInit {
  public readonly phase = inject(PHASE_TOKEN);
  public readonly isDevPhase = isDevPhase(this.phase);
  public readonly countryCode = toSignal(inject(CountryCodeService).countryCode);
  private readonly routerOutlet = viewChild.required(IonRouterOutlet);
  private readonly appService = inject(AppService);
  public readonly loggedIn = this.appService.loggedIn;
  public readonly currentVersion = this.appService.currentVersion.asReadonly();
  public readonly currentLocation = this.appService.currentHost;
  public readonly isOpenPhotoViewer = this.appService.isOpenPhotoViewer;
  public readonly currentPhoneNumber = this.appService.currentPhoneNumber;
  public readonly photoViewerParam = this.appService.photoViewerParam;

  constructor(injector: Injector) {
    registerLocaleData(localeKo, 'ko');
    AppInjector.getInstance().setInjector(injector);
    registerDialogWithBackButton(injector);
  }

  public async ngOnInit(): Promise<void> {
    this.appService.init$(this.routerOutlet()).subscribe();
  }

  public onClosePhotoViewer(): void {
    this.appService.closePhotoViewer();
  }

  public onLogout(): void {
    this.appService.logout();
  }

  public onCurrentUrlChange(url: string): void {
    this.appService.changeCurrentUrl(url);
  }

  public onDidDismiss(): void {
    this.appService.closePhotoViewer();
    this.appService.didDimissPhotoViewer();
  }
}
