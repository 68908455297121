import { inject } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { map, Observable, take, tap } from 'rxjs';

export function checkRedirectGuard(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  const auth = inject(Auth);
  const nav = inject(NavController);
  const loginAfterPath = inject(AppInfo).homeFullPath;
  const loginPath = inject(AppInfo).loginPath;
  const unauthenticatedRedirectUrl = inject(AppInfo).unauthenticatedRedirectUrl;
  return authState(auth).pipe(
    take(1),
    tap(user => {
      setTimeout(() => {
        if (user) {
          nav.navigateRoot(loginAfterPath, { replaceUrl: true });
        } else if (unauthenticatedRedirectUrl) {
          nav.navigateRoot(unauthenticatedRedirectUrl, { replaceUrl: true });
        } else {
          nav.navigateRoot(loginPath, { replaceUrl: true });
        }
      }, 10);
    }),
    map(() => false)
  );
}

