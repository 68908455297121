// en-US,en;q=0.9,ja;q=0.8,ko;q=0.7,ko-KR;q=0.6
import { Lang } from '@pixels/universal/model/i18n/i18n.model';

export function getLangFromAcceptLanguage(source: string, supportLanguages: string[], defaultLang = Lang.KO): Lang {
  const targetLang = source.slice(0, 2);
  if(supportLanguages.includes(targetLang)){
    return targetLang as Lang;
  }
  return defaultLang as Lang;
}
