import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { IonicApp } from '@pixels/client/ionic/ionic-app';
import { IonicDevice } from '@pixels/client/ionic/ionic-device';
import { forkJoin, Observable, switchMap } from 'rxjs';

export function environmentInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const ionicApp = inject(IonicApp);
  const ionicDevice = inject(IonicDevice);

  return forkJoin([
    ionicApp.getInfo(),
    ionicDevice.getInfo()
  ]).pipe(
    switchMap(([appInfo, deviceInfo]) => {
      if (appInfo) {
        const { version, build } = appInfo;
        req = req.clone({
          setHeaders: { 'X-APP-VERSION': `${version}-${build}` },
        });
      }
      const { platform } = deviceInfo;
      req = req.clone({
        setHeaders: { 'X-PLATFORM': `${platform}` },
      });

      return next(req);
    })
  );
}
