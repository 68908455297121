import { AppKeyAiChat } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'ai-chat-client';
const PROD_WEB_PUSH_KEY = 'BAqA4URBsrBPTeGm67r3VEW8bK_juf4H6_ZmQO6hCIHaTpW7FhplwCp7M81i1TxMvBLcPn4ATh4-bgN3Z6SINGw';
const DEV_WEB_PUSH_KEY = 'BINvahKxeq3GcBr0Ah207hJ5L_Nz1qKdGwlMbLmyH_MoN78CznDCNDFn64KIpQsFvEBRh5RmFLKC22pHkkD7zww';
const ProdFirebaseConfig = {
  apiKey: 'AIzaSyDCCXK9JTMgpmXoB75y48sppxbXZIRqXMw',
  authDomain: 'pixels-ai-chat.firebaseapp.com',
  projectId: 'pixels-ai-chat',
  storageBucket: 'pixels-ai-chat.appspot.com',
  messagingSenderId: '672737020027',
  appId: '1:672737020027:web:01a8de8bf3759518e73ade',
  measurementId: 'G-XV0DXNY98Q'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyAnvLQSSF4RRe7dXAuHuzzrperzNY5PR-0',
  authDomain: 'pixels-ai-chat-dev.firebaseapp.com',
  projectId: 'pixels-ai-chat-dev',
  storageBucket: 'pixels-ai-chat-dev.appspot.com',
  messagingSenderId: '135937987557',
  appId: '1:135937987557:web:d79129584f27f4eaded50c',
  measurementId: 'G-G003K3S3KY'
};
const PROD_TALK_PLUS_APP_ID = '24e8d9d2-dece-49b7-b345-7a96fa7a5609';
const DEV_TALK_PLUS_APP_ID = '618a8b13-f9f9-460e-abea-aaf7728569e4';

export const AiChatProjectMeta: ProjectMeta = {
  id: AppKeyAiChat,
  mainPrimaryColor: '#000000',
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod, Phase.dev],
  port: 8110,
  apiName: 'ai-chat-api',
  apiPort: 5010,
  appId: 'io.pixelteam.aichat',
  appStoreUrl: 'https://apps.apple.com/app/id6499507652',
  appName: 'AI 동호회',
  appNameEn: 'AI Club',
  appSchemeName: 'AI Club',
  testTargetUrl: 'https://test-aichat.pixelteam.io',
  devTargetUrl: 'https://dev-aichat.pixelteam.io',
  prodApi: 'https://aichat-api.pixelteam.io',
  devApi: 'https://dev-aichat-api.pixelteam.io',
  firebaseId: 'pixels-ai-chat',
  testHostname: 'test-aichat',
  clientSentryDsn: 'https://cdec21b0576734bb2d602909e4ebd931@o190729.ingest.us.sentry.io/4507158200909824',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10103/scenario/11290/deploy',
    targets: ['prod-00'],
    port: 8100,
    androidAppId: '1:672737020027:android:ee1d1d6f0a3d5e84e73ade',
    iosAppId: '1:672737020027:ios:0f11a8da2269cc5fe73ade',
    iosEncodingAppId: 'app-1-672737020027-ios-0f11a8da2269cc5fe73ade',
    webAppId: '1:672737020027:web:01a8de8bf3759518e73ade',
    kakaoAppKey: '0f68eaa52b52656f9e2833c12973ff3d',
    googleReversedId: 'com.googleusercontent.apps.672737020027-q0lpcaq2vdtdvkkvepigns0ommfjsgpf',
    webPushKey: PROD_WEB_PUSH_KEY,
    firebaseConfig: ProdFirebaseConfig,
    talkPlusAppId: PROD_TALK_PLUS_APP_ID,
    apiUrl: 'https://aichat-api.pixelteam.io',
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/11270/deploy',
    targets: ['dev-00'],
    port: 8101,
    androidAppId: '1:135937987557:android:b1461dbe7b43128aded50c',
    iosAppId: '1:135937987557:ios:78136c9eaae13730ded50c',
    iosEncodingAppId: 'app-1-135937987557-ios-78136c9eaae13730ded50c',
    webAppId: '1:135937987557:web:d79129584f27f4eaded50c',
    kakaoAppKey: 'c371539cfc77020e19e466cd07cb5283',
    googleReversedId: 'com.googleusercontent.apps.135937987557-j15eof6o5c91rg5g399ej8sn2umt6fpv',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://dev-aichat-api.pixelteam.io',
  },
  test: {
    androidAppId: '1:135937987557:android:dddb530651280840ded50c',
    iosAppId: '1:135937987557:ios:d4e9ed2df5199c5eded50c',
    iosEncodingAppId: 'app-1-135937987557-ios-d4e9ed2df5199c5eded50c',
    kakaoAppKey: 'c371539cfc77020e19e466cd07cb5283',
    googleReversedId: 'com.googleusercontent.apps.135937987557-d89aqdqbo7kse9og85crub6oglu1gmjj',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://test-aichat-api.pixelteam.io',
  },
  android: {
    admobAppId: 'ca-app-pub-4000507444081320~7662542913',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/5709299000',
  },
  ios: {
    admobAppId: 'ca-app-pub-4000507444081320~9882964843',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/8143890657',
  },
  NSCameraUsageDescription: '카메라로 찍은 사진을 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSPhotoLibraryAddUsageDescription: '회원님이 AI 동호회에 사진을 저장에 대한 액세스 권한을 사용합니다.',
  NSPhotoLibraryUsageDescription: '앨범에 있는 사진과 GIF를 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSCameraUsageDescriptionEn: 'You can attach photos taken with your camera to messages or register them as your profile picture',
  NSPhotoLibraryAddUsageDescriptionEn: 'You use access to save photos to AI Club',
  NSPhotoLibraryUsageDescriptionEn: 'You can attach photos and GIFs from your albums to messages or as profile pictures',
  consoleLogo: `
 █████╗ ██╗     ██████╗██╗  ██╗ █████╗ ████████╗
██╔══██╗██║    ██╔════╝██║  ██║██╔══██╗╚══██╔══╝
███████║██║    ██║     ███████║███████║   ██║   
██╔══██║██║    ██║     ██╔══██║██╔══██║   ██║   
██║  ██║██║    ╚██████╗██║  ██║██║  ██║   ██║   
╚═╝  ╚═╝╚═╝     ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝
`
};
