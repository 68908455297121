import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Share } from '@capacitor/share';
import { NavController, Platform } from '@ionic/angular/standalone';
import { AppConfigApiService } from '@pixels/client/app-config/app-config-api.service';
import { IonicApp } from '@pixels/client/ionic/ionic-app';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { compareVersions } from 'compare-versions';
import { map, Observable, of, switchMap } from 'rxjs';

export function instaChatConfigGuard(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  const appConfigApiService = inject(AppConfigApiService);
  const navCtrl = inject(NavController);
  const platform = inject(Platform);
  const ionicApp = inject(IonicApp);
  const appInfo = inject(AppInfo);
  const isNative = platform.is('hybrid');
  const { underMaintenancePath, versionUpWaitingPath } = appInfo;

  return appConfigApiService.fetchOne().pipe(
    switchMap(config => {
      if (config.isUnderMaintenance) {
        setTimeout(() => navCtrl.navigateRoot(underMaintenancePath), 10);
        return of(false);
      }

      if (isNative) {
        return ionicApp.getInfo().pipe(
          map(appInfo => {
            if (!appInfo) {
              return true;
            }
            if (compareVersions(config.minimumVersion, appInfo.version) > 0) {
              if (confirm(`업데이트가 필요합니다.\n업데이트 하시겠습니까?\n현재 버전${appInfo.version} 최소버전${config.minimumVersion}`)) {
                const url = platform.is('ios') ? config.appStoreUrl : config.playStoreUrl;
                Share.share({ url, title: '앱 업데이트' });
              }
              setTimeout(() => navCtrl.navigateRoot(versionUpWaitingPath), 10);
              return false;
            }
            return true;
          })
        );
      }

      return of(true);
    }),
  );
}
