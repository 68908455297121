import * as SentryAngular from '@sentry/angular';
import {
  SENTRY_MATCHER_FIREBASE_AUTH_NETWORK_ERROR,
  SENTRY_MATCHER_LOAD_CHUNK_ERROR,
  SENTRY_MATCHER_OVERLAY_DOES_NOT_EXIST_ERROR
} from './sentry.model';

export function initCapacitorAndAngular(dsn: string, environment: string): void {
  SentryAngular.init({
    dsn,
    integrations: [],
    environment,
    // tracesSampleRate: 1.0,
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      SENTRY_MATCHER_LOAD_CHUNK_ERROR,
      SENTRY_MATCHER_OVERLAY_DOES_NOT_EXIST_ERROR,
      SENTRY_MATCHER_FIREBASE_AUTH_NETWORK_ERROR,
    ],
  });
}
